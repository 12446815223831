<template>
  <div :class="{ 'tfw-job-board': isTFWPublicJobBoard }">
    <LoadingOverlay v-if="isFetchingGenericFilter" />
    <GenericFilter
      :basicFilterlength="getFilterSchema.basicFilterLength"
      :filterSchema="getFilterSchema.schema"
      :selectedFilters="selectedFilters"
      :filterDependencyObj="filterDependencyObj"
      :isFetchingFilter="isFetchingGenericFilter"
      :callback="updateRouterURL"
      :searchCallback="searchCallback"
      :filterAction="filterAction"
      :backURL="backURL" />
    <div class="job-board">
      <div v-if="isMobile && jobAdvertisement == true">
        <advertisement :allign="'horizontal'"></advertisement>
      </div>

      <div class="d-flex flex-row">
        <div
          class="d-flex flex-row w-100"
          v-if="
            !isFetchingGenericFilter &&
            !isJobBoardFetching &&
            !getJobBoard.length
          ">
          <div class="d-flex flex-column" style="margin-right: 20%">
            <h5 v-if="getJobCount" class="count">
              Total Jobs: {{ getJobCount }}
            </h5>
            <h5 class="count">Search Results: {{ getFilterCount }}</h5>
          </div>

          <div class="d-flex flex-column mt-5">
            <h4>
              No jobs matched the selected criteria
              <span v-if="AddJobRoles.includes(currentUserRole)"
                >, Please add a Job</span
              >
            </h4>
            <CButton
              v-if="AddJobRoles.includes(currentUserRole)"
              @click="navigateToAddJob()"
              class="btn-primary"
              title="Add Job"
              >Add Job</CButton
            >
          </div>
        </div>

        <div
          id="job-board-body"
          class="job-board-list col-md-4"
          v-if="
            !(
              !isFetchingGenericFilter &&
              !isJobBoardFetching &&
              !getJobBoard.length
            )
          ">
          <div>
            <h6 v-if="getJobCount" class="count">
              Total Jobs: {{ getJobCount }}<span class="pl-3"></span> Search
              Results: {{ getFilterCount }}
            </h6>
          </div>
          <job-card
            :key="index"
            :index="index"
            v-for="(job, index) in getJobBoard"
            :item="job"
            :class="
              !isMobile &&
              getSelectedJobBoard &&
              getSelectedJobBoard.job_id == job.job_id
                ? 'highlight'
                : 'disabled'
            "
            @click.native="jobCardSelectedCallback(job)"></job-card>
          <div v-if="noMoreDataFromJobBoard" class="no-more-data">
            <p v-if="getJobBoard.length > 0" class="btn-primary">
              No More Data!
            </p>
          </div>
          <div
            v-if="!noMoreDataFromJobBoard"
            class="d-flex justify-content-around align-items-center has-loading-overlay"
            style="height: 200px">
            <LoadingOverlay v-if="isJobBoardFetching" />
          </div>
        </div>
        <div
          class="job-board-detail col-md-6"
          :class="{ 'col-md-8': jobAdvertisement == false }"
          v-if="getJobBoard.length > 0 || isJobBoardFetching">
          <CModal
            id="applyjobModal"
            title="Apply Job"
            color="primary"
            centered:false
            @update:show="onShow"
            :show.sync="successModal"
            >{{ this.modalMessage }}</CModal
          >
          <div
            class="skeleton-card"
            v-if="!(getSelectedJobBoard && getSelectedJobBoard.job_id)"></div>
          <job-detail v-else :getSelectedJobBoard="getSelectedJobBoard">
          </job-detail>
        </div>
        <div
          v-if="isMobile != true && jobAdvertisement == true"
          class="col-lg-2"
          style="padding-right: 0px">
          <advertisement :allign="'verticle'"></advertisement>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jobCard from "../components/jobCard.vue";
import jobDetail from "../components/JobBoardDetail/jobDetail.vue";
import { mapGetters, mapActions } from "vuex";
import TextInput from "@/components/reusable/Fields/TextInput";
import router from "../router/index";
import {
  LOGIN_URL,
  getScope,
  Role,
  getLocalOrgDetail,
  isEmptyObjectCheck,
  isObject,
} from "@/helpers/helper";
import GenericFilter from "@/components/reusable/GenericFilterAndSearch/index";
import advertisement from "@/components/reusable/Advertise.vue";

export default {
  components: {
    "job-card": jobCard,
    "job-detail": jobDetail,
    TextInput,
    GenericFilter,
    advertisement,
  },
  data() {
    return {
      searchTerm: "",
      isMobile: false,
      successModal: false,
      modalMessage: "",
      page: "",
      currentUserRole: getScope(),
      Role,
      urlFullPath: "",
      jobId: "",
      jobAdvertisement: false,
      AddJobRoles: [
        Role.systemAdmin,
        Role.customerAdmin,
        Role.customerRecruiter,
        // Role.customerCandidate,
        Role.systemCandidate,
        Role.systemRecruiter,
      ],
    };
  },
  async mounted() {
    const { cus_id, org_id } = this.$route.params;
    let payload = {};

    if (cus_id) {
      payload = {
        customer_id: cus_id,
      };

      if (org_id) {
        payload.organisation_id = org_id;
      }
    }

      await this.fetchJobAdvertisement(payload);
      this.getCustomerAdvertisement(cus_id).then((data) => {
        const jobAd = data?.find((val) => val.key === "JOB_ADVERTISEMENT");

        if (jobAd && jobAd.value === "TRUE" && this.activeJobAdvertisement.length > 0) {
          this.jobAdvertisement = true;
        } else {
          this.jobAdvertisement = false;
        }
      });

    this.scrollTop();
    this.urlFullPath = this.$route.fullPath;
    let appendAction = [];
    if (!(isObject(this.getUser) && !isEmptyObjectCheck(this.getUser)))
      appendAction = [...appendAction, this.fetchUser()];
    Promise.all(appendAction).then((res) => {
      if (this.getIsParentUser)
        this.setCurrentPageSchema({ schema: "jobBoardSchemaForParentOrg" });
      if (this.isUKMainAgency) {
        // Set Pertemps Schema
        this.setCurrentPageSchema({ schema: "jobBoardPertempsSchema" });
      }
    });
    this.initAllFilters().then(async () => {
      // await this.clearAllSelectedFilters();
      let initialEmptySelectedCheck =
        await this.updateSelectedFilterFromFilterCode(
          this.$router.currentRoute.query
        );
      let queryUrl = this.getQueryResult.queryUrl;
      if (this.currentUserRole) {
        if (
          ["customer_candidate", "system_candidate"].includes(
            this.currentUserRole
          )
        ) {
          if (
            !(
              isObject(this.candidateProfile) &&
              !isEmptyObjectCheck(this.candidateProfile)
            )
          )
            await this.getUserMe({ customer: this.getCustomerId });
          await this.candidateJobCount({ query: "", isTotal: true });
        } else if (["system_admin"].includes(this.currentUserRole)) {
          await this.jobCount({ query: "", isTotal: true });
        } else if (
          ["customer_admin"].includes(this.currentUserRole) &&
          this.getRelationshipOrgDetail?.length
        ) {
          await this.agencyJobCount({ query: "", isTotal: true });
        } else {
          await this.facilityJobCount({ query: "", isTotal: true });
        }
      } else await this.jobCount({ query: "", isTotal: true });
      const pageNumber = parseInt(this.$router.currentRoute?.query?.page || 1);
      //Todo working on the two api calls issue
      this.jobBoardAction({
        page: this.page,
        query: this.getQueryResult.queryUrl,
        pageNumber,
      }).then(() => {
        let isMobile = window.matchMedia(
          "only screen and (max-width: 760px)"
        ).matches;
        this.isMobile = isMobile;
        let ele;
        if (!isMobile) {
          ele = document.getElementById("job-board-body");
        } else {
          if (this.urlFullPath.includes("/public")) {
            ele = document.getElementById("c-body-app");
          } else {
            ele = document.getElementById("c-body-app");
          }
        }
        if (ele && this.$route?.path != "/job-list") {
          ele.addEventListener("scroll", this.onBodyScroll);
        }
      });
    });
    this.$store.commit("RESET_JOB_LIST_BACK_URL");
  },
  beforeDestroy() {
    let ele;
    if (!this.isMobile) {
      ele = document.getElementById("job-board-body");
    } else {
      if (this.urlFullPath.includes("/public")) {
        ele = document.getElementById("app");
      } else {
        ele = document.getElementById("c-body-app");
      }
    }
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },

  created() {
    if (this.getRoleScopes.length) {
      if (
        [
          "system_admin",
          "customer_candidate",
          "system_recruiter",
          "system_candidate",
        ].includes(this.getRoleScopes[0])
      ) {
        this.page = "publicJobBoard";
      } else {
        this.page = "jobBoard";
      }
    } else {
      this.page = "publicJobBoard";
    }
    this.setCurrentPageSchema({ schema: "jobBoardSchema" });
  },

  updated() {
    let { job_id } = this.$route.query;
    const jobId = parseInt(job_id) | 0;
    if (jobId !== this.jobId) {
      this.selectedJobBoard(parseInt(jobId));
    }
    this.jobId = jobId;
    let ele = document.getElementById("job-board-body");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
  methods: {
    ...mapActions([
      "scrollTop",
      "jobBoardAction",
      "selectedJobBoard",
      "applyJob",
      "showToast",
      "setCurrentPageSchema",
      "initAllFilters",
      "updateSelectedFilter",
      "clearSearchFilterWithUpdateSearch",
      "updateSelectedFilterFromFilterCode",
      "clearAllSelectedFilters",
      "jobCount",
      "facilityJobCount",
      "getUserMe",
      "candidateJobCount",
      "agencyJobCount",
      "fetchUser",
      "fetchAcronymByOrgDetail",
      "getGenericSpecialitySearchBasedOnId",
      "getGenericSpecialitySearchBasedOnCode",
      "getCustomerAdvertisement",
      "fetchJobAdvertisement",
    ]),
    searchCallback(payload) {
      payload["type"] = "searchTerm";
      this.updateRouterURL(payload);
    },
    jobCardSelectedCallback({ job_id }) {
      if (this.isMobile) {
        if (this.getRoleScopes.length) {
          if (
            this.getRoleScopes[0] === "system_admin" ||
            this.getRoleScopes[0] === "system_recruiter"
          ) {
            this.$router.push({ path: `/public/job-board-detail/${job_id}` });
          } else if (
            this.getRoleScopes[0] === "customer_admin" ||
            this.getRoleScopes[0] === "customer_recruiter"
          ) {
            this.$router.push({ path: `/job-board-detail/${job_id}` });
          } else if (
            ["customer_candidate", "system_candidate"].includes(
              this.getRoleScopes[0]
            )
          ) {
            this.$router.push({ path: `/public/job-board-detail/${job_id}` });
          } else {
            this.$router.push({ path: `/job-board-detail/${job_id}` });
          }
        } else if (this.isTFWPublicJobBoard) {
          this.$router.push({ path: `/tfw/job-board-detail/${job_id}` });
        } else {
          this.$router.push({ path: `/public/job-board-detail/${job_id}` });
        }
      } else {
        let path = `${this.$route.path}?job_id=${job_id}&${this.getQueryResult.routeUrl}`;
        this.$router
          .push({
            path,
          })
          .catch(() => {});
        this.selectedJobBoard(parseInt(this.$route.query.job_id));
      }
    },
    onBodyScroll(e) {
      if (this.noMoreDataFromJobBoard || this.isJobBoardFetching) return;
      if (
        e.target.clientHeight + e.target.scrollTop >=
        e.target.scrollHeight - 1
      ) {
        this.jobBoardAction({
          pagination: true,
          page: this.page,
          query: this.getQueryResult.queryUrl,
        });
      }
    },
    handleSearchSubmit() {
      this.jobBoardAction(this.searchTerm);
    },
    handleSearchInput(name, value) {
      this.searchTerm = value;
    },
    clearSearch() {
      this.searchTerm = "";
      this.jobBoardAction();
    },
    onApplyJob() {
      this.successModal = true;
      if (this.getRoleScopes.length) {
        this.modalMessage = "You have selected the Job to Apply";
      } else {
        this.modalMessage = "Please login/Register to apply";
      }
    },
    navigateToAddJob() {
      router.push("/add-job?step=0");
    },
    onShow(e, value) {
      if (value.target.textContent === " OK ") {
        if (!this.isTFW) {
        let { path_acronym, customer_uid, organisation_id, location_id } =
          getLocalOrgDetail();
        let path = `/${path_acronym}/register/${customer_uid}`;
        if (organisation_id)
          path += `/organisation/${organisation_id}/location/${location_id}`;
        let query = {
          autologin: true,
          redirect_url: '/jobs-list'
        };
        this.$router.push({ path: path, query: query });
      }
      else if (this.isTFW) {
        this.$router.push({ path: `/tfw/registration/candidate` });
      }}
    },
    updateRouterURL(payload) {
      let appendAction = [];
      if (payload?.name === "specialitySearch")
        appendAction = [
          ...appendAction,
          this.getGenericSpecialitySearchBasedOnCode(payload),
        ];
      else appendAction = [...appendAction, this.updateSelectedFilter(payload)];
      Promise.all(appendAction).then((res) => {
        let path = `${this.$route.path}?${this.getQueryResult.routeUrl}`;
        this.$router.push({ path });
      });
    },
    filterAction(isAsync = false) {
      if (isAsync) {
        const appendAction = this.getGenericSpecialitySearchBasedOnId();
        Promise.all([appendAction]).then(async (res) => {
          await this.updateSelectedFilterFromFilterCode(
            this.$router.currentRoute.query
          );
          this.jobBoardAction({
            page: this.page,
            query: this.getQueryResult.queryUrl,
          });
        });
      } else {
        this.initAllFilters().then(async () => {
          await this.clearAllSelectedFilters();
          await this.updateSelectedFilterFromFilterCode(
            this.$router.currentRoute.query
          );
          this.jobBoardAction({
            page: this.page,
            query: this.getQueryResult.queryUrl,
          });
        });
      }
    },
  },
  computed: {
    customMarginBasedOnPage() {
      if (
        this.getRoleScopes.length &&
        this.$route.path !== "/public/job-board"
      ) {
        return 10;
      }
      return 70;
    },
    backURL() {
      return this.isTFWPublicJobBoard ? `/tfw#sec-head-2` : "";
    },
    ...mapGetters([
      "getJobBoard",
      "getSelectedJobBoard",
      "noMoreDataFromJobBoard",
      "isJobBoardFetching",
      "getJobCount",
      "getFilterCount",
      "getRoleScopes",
      "jobBoardSchema",
      "jobBoardSchemaForCandidate",
      "jobBoardSchemaForParentOrg",
      "jobBoardPertempsSchema",
      "selectedFilters",
      "filterDependencyObj",
      "isFetchingGenericFilter",
      "getQueryResult",
      "isSelectedFilterUpdated",
      "getCustomerId",
      "candidateProfile",
      "getRelationshipOrgDetail",
      "getIsParentUser",
      "getUser",
      "setNewOrgAcronym",
      "getSelectedCustomerVariant",
      "isUKMainAgency",
      "isJobSupplierFromAccessToken",
      "getJobBoardPaginationLimit",
      "isSuperCustomer",
      "jobAdvertisements",
    ]),
    getFilterSchema() {
      this.variant = this.getSelectedCustomerVariant;

      return this.currentUserRole
        ? { schema: this.jobBoardSchema, basicFilterLength: 4 }
        : { schema: this.jobBoardSchemaForCandidate, basicFilterLength: 5 };
    },
    isTFWPublicJobBoard() {
      const { name } = this.$route;
      return ["TFW Job Board"].includes(name) || false;
    },
    isTFW() {
      return this.$route?.path.includes("/tfw") || false;
    },
    activeJobAdvertisement() {
      return this.jobAdvertisements.filter(({ is_active }) => is_active) || [];
    },
  },
};
</script>
